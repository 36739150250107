<template>
    <Transition name="fade-popup">
        <div v-if="isOpen" class="relative popup-add-product w-max text-sm pl-4 pr-8 py-3 transform duration-200">
            <div class="flex items-center gap-2 text-sm">
                <i class="i-ic:baseline-check-circle bg-[#01AB56]"></i>
                <p>Thêm vào giỏ hàng thành công</p>
            </div>
            <nuxt-link
                :to="{ name: 'shopping_cart' }"
                class="p-2 bg-black hover:bg-black/80 text-white rounded-md text-center mt-2 cursor-pointer block"
            >
                Xem giỏ hàng và liên hệ
            </nuxt-link>
            <div
                @click="storeShoppingCart.isOpenPopupCart = false"
                class="w-5 h-5 col-flex rounded-full absolute top-1 right-1 cursor-pointer"
            >
                <i class="i-ic:baseline-cancel bg-gray-400 hover:bg-gray-500"></i>
            </div>
        </div>
    </Transition>
</template>

<script setup lang="ts">
import { useGetShoppingCart } from '../../stores/useShoppingCart'
const storeShoppingCart = useGetShoppingCart()
const isOpen = computed(() => storeShoppingCart.isOpenPopupCart)
const flat = ref(false)
const countNumberProducts = computed(() => storeShoppingCart.checkProduct())
const checkPopup = computed(() => storeShoppingCart.numberProduct.length)
watch(
    () => isOpen.value,
    (newValue, oldValue) => {
        if (useRoute().name !== 'product_detail') return (storeShoppingCart.isOpenPopupCart = false)
        if (storeShoppingCart.isOpenPopupCart) {
            setTimeout(() => {
                storeShoppingCart.isOpenPopupCart = false
            }, 3000)
        }
    }
)
onMounted(() => {})
</script>

<style>
.popup-add-product {
    position: absolute;
    top: 100%;
    right: 0px;
    margin-top: 20px;
    background-color: rgb(255, 255, 255);
    border-radius: 6px;
    box-shadow: rgb(179, 179, 179) 1px 1px 15px;
    z-index: 1000;
}
.popup-add-product:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    right: 20px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid white;
}
.fade-popup-enter-active {
    transition: all 0.1s ease-out;
}

.fade-popup-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-popup-enter-from,
.fade-popup-leave-to {
    transform: translateY(10px);
    opacity: 0;
}
</style>
